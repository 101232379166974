import React, { useEffect, useState, useRef } from 'react';
import './App.css';

const rainAudio = new Audio('rain.wav');
rainAudio.volume = 0.40;
const daytimeAudio = new Audio('summer_day.wav');
daytimeAudio.volume = 0.40;
const nighttimeAudio = new Audio('night_time.wav');
nighttimeAudio.volume = 0.40;

function App() {
  const [currentWeatherData, setWeatherData] = useState(null);
  const canvasRef = useRef(null);
  const [isMuted, setIsMuted] = useState(true);
  const [isRaining, setIsRaining] = useState(false);
  const [isThunder, setIsThunder] = useState(false);
  const [temperature, setTemperature] = useState(0);
  const [isDaytime, setIsDaytime] = useState(true);

  useEffect(() => {
    const fetchWeatherData = async (latitude, longitude) => {
      try {
        const response = await fetch(`https://api.weather.gov/points/${latitude},${longitude}`);
        const zoneResponse = await response.json();
        if (!zoneResponse) {
          throw new Error('No weather station data found');
        }
        const fetchStationResponse = await fetch(zoneResponse.properties.observationStations);
        const stationData = await fetchStationResponse.json();
        if (!stationData) {
          throw new Error('No weather station data found');
        }
        const fecthLatestObservationResponse = await fetch(stationData.features[0].id + '/observations/latest');
        const observationData = await fecthLatestObservationResponse.json();
        if (!observationData) {
          throw new Error('No observation data found');
        }
        const currentData = observationData.properties;
        setIsRaining(currentData.textDescription.toLowerCase().includes('rain') || currentData.textDescription.toLowerCase().includes('showers'));
        setIsThunder(currentData.textDescription.toLowerCase().includes('thunder'));
        setTemperature(currentData.temperature.value * 9 / 5 + 32);
        setIsDaytime(currentData.timestamp.includes('T') && currentData.timestamp.split('T')[1].split(':')[0] >= 10 && currentData.timestamp.split('T')[1].split(':')[0] < 22);
        setWeatherData(currentData);
      } catch (error) {
        console.error('Error fetching weather data:', error);
      }
    };
    // Get user's current latitude and longitude
    const fetchWeatherDataWithLocation = () => {
      const queryParameters = new URLSearchParams(window.location.search);
      if(queryParameters.has('lat') && queryParameters.has('long')) {
        const latitude = queryParameters.get('lat');
        const longitude = queryParameters.get('long');
        fetchWeatherData(latitude, longitude);
      }
      else {
        navigator.geolocation.getCurrentPosition(
          (position) => {
            const { latitude, longitude } = position.coords;
            fetchWeatherData(latitude, longitude);
          },
          (error) => {
            console.error('Error getting current position:', error);
          }
        );
      }
    };
  
    // Fetch weather data initially
    fetchWeatherDataWithLocation();
  
    // Fetch weather data every 5 minutes
    const interval = setInterval(fetchWeatherDataWithLocation, 5 * 60 * 1000);
  
    return () => {
      clearInterval(interval);
    };
  }, []);

  useEffect(() => {
    let animationFrameId;
    let interval;
    const canvas = canvasRef.current;
    const ctx = canvas.getContext('2d');
    const raindrops = [];

    const createRaindrop = () => {
      const x = Math.random() * canvas.width * 1.2;
      const y = -20;
      const speed = Math.random() * 5 + 8;
      const length = Math.random() * 20 + 5;

      raindrops.push({ x, y, speed, length });
    };

    const updateRaindrops = () => {
      ctx.clearRect(0, 0, canvas.width, canvas.height);

      raindrops.forEach((raindrop, index) => {
        raindrop.y += raindrop.speed;

        raindrop.x -= raindrop.speed / 6;
        ctx.beginPath();
        ctx.moveTo(raindrop.x, raindrop.y);
        ctx.lineTo(raindrop.x - raindrop.length / 6, raindrop.y + raindrop.length);
        ctx.strokeStyle = '#b0e8f7';
        ctx.lineWidth = 3;
        ctx.stroke();

        if (raindrop.y > canvas.height) {
          raindrops.splice(index, 1);
        }
      });

      animationFrameId = requestAnimationFrame(updateRaindrops);
    };

    if (isRaining) {
      interval = setInterval(createRaindrop, 10);
      animationFrameId = requestAnimationFrame(updateRaindrops);

      const handleVisibilityChange = () => {
        if (document.hidden) {
          clearInterval(interval);
          cancelAnimationFrame(animationFrameId);
        } else {
          interval = setInterval(createRaindrop, 10);
          animationFrameId = requestAnimationFrame(updateRaindrops);
        }
      };

      document.addEventListener('visibilitychange', handleVisibilityChange);

      return () => {
        clearInterval(interval);
        cancelAnimationFrame(animationFrameId);
        document.removeEventListener('visibilitychange', handleVisibilityChange);
      };
    }
  }, [isRaining]);
  
  const toggleMute = () => {
    setIsMuted(!isMuted);
  };

  const prevDepsRef = useRef({ isRaining, isThunder, isMuted, isDaytime });
  useEffect(() => {
    

    const playRandomAudio = () => {
      const audioClips = ['sing1.wav', 'sing2.wav', 'sing3.wav'];
      const randomIndex = Math.floor(Math.random() * audioClips.length);
      const audioClip = audioClips[randomIndex];
      const audio = new Audio(audioClip);
      audio.volume = 0.4;
      audio.play();
    };

    const rainRandomSoundInterval = setInterval(() => {
      if (!isMuted && isRaining) {
        playRandomAudio();
      }
    }, Math.random() * 45_000 + 15_000);

    const rainLoopInterval = setInterval(() => {
      if (!isMuted && isRaining) {
        rainAudio.currentTime = 0;
        rainAudio.play();
      }
    }, 12_000);

    const thunderInterval = setInterval(() => {
      if(!isMuted && isThunder) {
        const audio = new Audio('thunder.wav');
        audio.volume = 0.4;
        audio.play();
      }
    }, Math.random() * 15_000 + 15_000);

    const daytimeInterval = setInterval(() => {
      if(!isMuted && isDaytime && !isRaining) {
        daytimeAudio.play();
      }
    }, 45_000);

    const nighttimeInterval = setInterval(() => {
      if(!isMuted && !isDaytime && !isRaining) {
        nighttimeAudio.play();
      }
    }, 4_000);

    if (prevDepsRef.current) {
      if (prevDepsRef.current.isMuted !== isMuted) {
        if (isMuted) {
          rainAudio.pause();
          daytimeAudio.pause();
          nighttimeAudio.pause();
        } else {
          if(isRaining) {
            rainAudio.play();
          } else if(isDaytime) {  
            daytimeAudio.play();
          } else {  
            nighttimeAudio.play();
          }
        }
      }
    }
    prevDepsRef.current = { isRaining, isThunder, isMuted, isDaytime };
    return () => {
      clearInterval(rainRandomSoundInterval);
      clearInterval(thunderInterval);
      clearInterval(rainLoopInterval);
      clearInterval(daytimeInterval);
      clearInterval(nighttimeInterval);
    };
  }, [isRaining, isThunder, isMuted, isDaytime]);

  return (
    <>
    <div
      className="flex items-center justify-center h-screen"
      style={{
        backgroundImage: `url(${isDaytime ? 'day.png' : 'night.png'})`,
        backgroundPosition: 'center',
        backgroundSize: 'cover',
        backgroundRepeat: 'no-repeat',
      }}
    >
      <canvas ref={canvasRef} className="absolute top-0 right-0 bottom-0 left-0" width={window.innerWidth} height={window.innerHeight} />
      <div className="text-left">
        {currentWeatherData && (
          <>
            <p className="text-8xl font-bold font-stardew text-white">
              {temperature.toFixed(0)}
              <span className="text-7xl">F</span>
            </p>
            <p className="text-5xl font-stardew text-white">{currentWeatherData.textDescription}</p>
          </>
        )}
      </div>
      <img
        className="absolute top-4 right-4 w-8 h-8"
        src={isMuted ? 'mute.png' : 'playing.png'}
        alt={isMuted ? 'Mute' : 'Unmute'}
        onClick={toggleMute}
      />
    </div>
    </>
  );
}

export default App;
